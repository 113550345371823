import { addMinutes, closestTo, roundToNearestMinutes } from 'date-fns';

/**
 * Departure Times
 * ------------------------------------------------------------------------------------------
 * Show possible, selectable departure times (both departure/arrival times) for trip up
 */
export default function useDepartureTime(
    tripsUpFiltered: ComputedRef<Trip[]>,

) {
    const shopStore = useShopStore();
    const product = computed(() => shopStore.productCurrentP2P);

    // Navigate list by departure or arrival time
    const departureTimeMode = ref<'departureTime' | 'arrivalTime'>('departureTime');

    // The selected time in dropdown (used wheter mode is departure or arrival)
    const departureTime = ref<Date>();

    // Rounding method for searching closest trip
    // When searching by departure time, we want to find the closest trip that leaves AFTER the selected time
    // When searching by arrival time, we want to find the closest trip that arrives BEFORE the selected time
    const roundingMethod = computed(() => departureTimeMode.value === 'departureTime' ? 'floor' : 'ceil');
    const departureTimeIteration = computed(() => product.value?.config.departureTimeIteration === 30 ? 30 : undefined);

    // The first (lowest) possible departure time option (e.g. 06:00). Rounded to nearest iteration
    const departureTimeMin = computed(() => {
        if (!tripsUpFiltered.value.length)
            return;

        const time = tripsUpFiltered.value[0]![departureTimeMode.value];
        return roundToNearestMinutes(time, { roundingMethod: roundingMethod.value, nearestTo: departureTimeIteration.value });
    });

    // The last (highest) possible departure time option (e.g. 17:30). Rounded to nearest iteration
    const departureTimeMax = computed(() => {
        if (!tripsUpFiltered.value.length)
            return;
        const lastConnection = tripsUpFiltered.value[tripsUpFiltered.value.length - 1]!;
        return roundToNearestMinutes(lastConnection[departureTimeMode.value], { roundingMethod: roundingMethod.value, nearestTo: departureTimeIteration.value });
    });

    // The final list of selectable departure times for the dropdown (e.g. 06:00, 06:30, 07:00, ..., 17:30)
    const departureTimes = computed(() => {
        if (!departureTimeMin.value || !departureTimeMax.value)
            return [];
        const departureTimes: Date[] = [];
        let current = departureTimeMin.value;
        while (isBeforeOrEqual(current, departureTimeMax.value)) {
            departureTimes.push(current);
            current = addMinutes(current, product.value?.config.departureTimeIteration ?? 0);
        }
        return departureTimes;
    });

    /**
     * Update currently selected departure time
     * - Either because user selected a new time from dropdown
     * - Or because user scrolled the list and the dropdown should update
     * @param time either time of a trip (when scrolled) or time selected, so we need to find closest
     */
    function updateDepartureTime(time: Date) {
        const timeRounded = roundToNearestMinutes(time, { roundingMethod: roundingMethod.value, nearestTo: departureTimeIteration.value });
        departureTime.value = closestTo(timeRounded, departureTimes.value);
    }

    return {
        departureTimeMode,
        departureTime,
        departureTimes,
        updateDepartureTime,
    };
}
