// @ts-expect-error haltdschnorre
import structuredClone from '@ungap/structured-clone';
import { isReactive, toRaw } from 'vue';

function isObject(value: unknown): boolean {
    return value !== null && !Array.isArray(value) && typeof value === 'object';
}

function getRawData<T>(data: T): T {
    return isReactive(data) ? toRaw(data) : data;
}

function toDeepRaw<T>(data: T): T {
    const rawData = getRawData<T>(data);

    for (const key in rawData) {
        const value = rawData[key];

        if (!isObject(value) && !Array.isArray(value)) {
            continue;
        }

        rawData[key] = toDeepRaw<typeof value>(value);
    }

    return rawData;
}

/**
 *
 * Clones an object using structuredClone.
 * Because structuredClone can not work with Proxy objects and
 * vue uses Proxy objects for reactive data, we need to convert
 * the object and all children to raw data before cloning.
 *
 * Please use this over structuredClone() or JSON.parse(JSON.stringify())
 *
 * @param object
 * @returns cloned object
 */
export function deepClone<T>(object: T): T {
    return structuredClone(toDeepRaw(object));
}
