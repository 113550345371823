import useOffers from './useOffers';
import useProducts from './useProducts';
import useQuote from './useQuote';

/**
 * Shop Store
 * ----------------------------
 *
 * The shop store contains all data related to the products, offers and quotes
 * a customer selects to buy during the offer flow.
 *
 * A product holds information like dates, times, activityId etc.
 * The product is aggregated in the different steps going through the offer flow.
 *
 * - In the main flow, the main product is directly edited
 * - If entering an upsell flow, the upselled product is stored in "productsUpsell"
 * - Once upsell is finished, the product is moved back to main products
 * - So at the end, all products end up in "productsMain"
 *
 * Why? The main products hold all final products, ready to go to checkout
 * The upsell products are temporary and changes are only saved to the main list,
 * once the customer saves the upsell and goes back to the main flow.
 *
 * The shop store also makes sure changes to passengers are reflected in all products.
 * If relevant changes are made to products, the offers are reloaded.
 *
 * If offers are updated, the quote is reloaded to make sure the final price is up-to-date.
 */
export const useShopStore = defineStore('shop', () => {
    /**
     * Products
     * ------------------------------------------------------------------------------------------
     */
    const {
        productsMain, // Main (final) prduct list
        productsUpsell, // Products in upsell flow
        productsCurrent, // Current active flow products (main or upsell)
        productMain, // Product currently active in main flow
        productUpsell, // Product currently active in upsell flow
        productCurrent, // Product currently active
        productCurrentHotel, // Product currently active (typed)
        productCurrentActivity, // Product currently active (typed)
        productCurrentP2P, // Product currently active (typed)
        hashedProducts, // Hash of each product to detect changes
        addProduct, // Add product to current flow (main or upsell)
        removeProduct, // Delete product completely
        saveUpsell, // Save upsell (move to main products)
        editUpsell, // Edit upsell (move to upsell products)
        removeProductUpsell, // Delete upsell product
    } = useProducts();

    /**
     * Offers
     * ------------------------------------------------------------------------------------------
     */
    const {
        offers, // List of all offers, extracted from products
        offerCurrent, // Offer for the current active flow
        isSyncOffers, // If offers should be reloaded on product changes
        loadOffer, // Fetch new offers from API
    } = useOffers(
        productsMain,
        hashedProducts,
    );

    /**
     * Quote
     * ------------------------------------------------------------------------------------------
     */
    const {
        quote, // Quote for all offers
        loadQuote, // Load quote for all offers
        resetQuote, // Reset quote
    } = useQuote(
        offers,
    );

    /**
     * Reset
     * ------------------------------------------------------------------------------------------
     */
    function reset() {
        productsMain.value = {};
        productsUpsell.value = {};
        isSyncOffers.value = false;
        quote.value = undefined;
    }

    return {
        // Products
        productsMain,
        productsUpsell,
        productsCurrent,
        productMain,
        productUpsell,

        productCurrent,
        productCurrentHotel,
        productCurrentActivity,
        productCurrentP2P,

        hashedProducts,
        addProduct,
        removeProduct,
        saveUpsell,
        editUpsell,
        removeProductUpsell,

        // Offers
        offers,
        isSyncOffers,
        offerCurrent,
        loadOffer,

        // Quote
        quote,
        loadQuote,
        resetQuote,

        reset,
    };
}, {
    persist: {
        omit: [
            'isSyncOffers',
        ],
        ...storePersist.storePersistOptions,
    },
});
