/**
 * Joch Route Variants
 * ------------------------------------------------------------------------------------------
 *
 * Route variants are different ways to get up/down to Joch. For both ways, users can select
 * a desired variant. In case variants are changed, the trips need to be reloaded.
 */
export default function useJochVariants() {
    const routeVariantUp = ref<RouteVariant | undefined>(undefined);
    const routeVariantDown = ref<RouteVariant | undefined>(undefined);
    return {
        routeVariantUp,
        routeVariantDown,
    };
}
