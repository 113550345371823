import { isEqual } from 'date-fns';

type ResultType = OfferActivity | undefined;
type ApiResponseType = ApiSchema['OfferCollectionResponse'];
type ParamsType = ApiSchema['OfferCollectionRequest'];
type Warning = ApiSchema['Warning'];

/**
 * Loads a single offer for a specific activity product and passengers.
 * e.g. 1 combined offer for 3 people going skiing for 3 days.
 */
export default async function apiLoadActivityOffer(product: ProductActivity, passengers: Passenger[]): Promise<ApiResult<ResultType>> {
    try {
        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/offers',

            // Request body
            {
                offerSearchCriteria: {
                    validFrom: formatDateTimeISO(product.dateFrom),
                    activityIds: [
                        product.activityId,
                    ],
                },
                anonymousPassengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards,
                })),
            },

            // Transform result
            (response: ApiResponseType) => {
                const offer = response.offers?.find((o) => {
                    const validFrom = zurichDate(o.activityOfferParts?.[0]?.validFrom);
                    const validUntil = zurichDate(o.activityOfferParts?.[0]?.validUntil);
                    return product.dateFrom
                        && product.dateUntil
                        && isEqual(validFrom, product.dateFrom)
                        && isEqual(validUntil, product.dateUntil);
                });

                if (offer) {
                    return transformOfferActivity(offer, passengers, response.warnings);
                }
                return undefined;
            },
        );
    }
    catch (error) {
        handleError(error, { product, passengers });
        return {
            ok: false,
            warnings: [],
            data: undefined,
        };
    }
}
