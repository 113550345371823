/**
 * Activity Store
 * ----------------------------
 *
 * Stores necessary data for single-day activities flow (e.g. swissactivities)
 */
export const useActivityStore = defineStore('activity', () => {
    const { isLoading } = useLoading();

    // List of activities to display
    const activities = ref<ActivityInfo[]>();

    // List of offers for 1 specific activity
    const activityOffers = ref<AvailableActivityOffer[]>();

    // Selected time for the activity (string, not date, for better comparison in buttongroup)
    const selectedTime = ref<string>();

    /**
     * ------------------------------------------------------------------------------------------
     * Load activities list
     */
    async function loadActivityList(categoryId: string) {
        isLoading.value = true;

        // Reset
        activities.value = [];
        activityOffers.value = [];
        selectedTime.value = undefined;

        const { ok, data } = await apiLoadActivities(categoryId);
        if (ok) {
            activities.value = data;
        }

        isLoading.value = false;
        return ok;
    }

    /**
     * ------------------------------------------------------------------------------------------
     * Load activity offers
     */
    async function loadActivityOffers(product: ProductActivity | undefined) {
        if (!product) {
            return false;
        }

        isLoading.value = true;

        // Reset
        activityOffers.value = [];

        const { ok, data } = await apiLoadAvailableActivityOffers(
            product.activityId,
            product.dateFrom!,
        );
        if (ok) {
            activityOffers.value = data;
        }

        isLoading.value = false;
        return ok;
    }

    /**
     * Reset
     */
    function reset() {
        activities.value = [];
        activityOffers.value = [];
        selectedTime.value = undefined;
    }

    return {
        activities,
        loadActivityList,

        activityOffers,
        selectedTime,
        loadActivityOffers,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
