type ProductHash = Record<ProductKey, string>;

/**
 * Offers
 * ----------------------------
 *
 * Manages offers for main products
 */
export default function useOffers(
    productsMain: Ref<Products>,
    hashedProducts: ComputedRef<ProductHash>,
) {
    const flowStore = useFlowStore();
    const passengerStore = usePassengerStore();
    const { isLoading } = useLoading();

    /**
     * Offers
     * ---------------------------------------------------
     * Offers are stored inside the products.
     * Extracts offers from products as
     * [ProductKey + Offer] for each product
     *
     */
    const offers = computed<Offers>(() => {
        return Object.entries(productsMain.value).reduce<Offers>((acc, [key, product]) => {
            if (product.offer) {
                acc[key as ProductKey] = product.offer as Offer;
            }
            return acc;
        }, {} as Offers);
    });

    /**
     * The offer for the current active booking flow
     * ---------------------------------------------------
     * The currently active offer for the main flow you are in
     *
     */
    const offerCurrent = computed(() => {
        return flowStore.productConfigMain && offers.value[flowStore.productConfigMain?.key];
    });

    /**
     * Wheter offers should be reloaded on product changes
     * ---------------------------------------------------
     * Stores if currently, offers should be reloaded when products change.
     * You dont want to have this all the time, but only on relevant pages.
     * (e.g. you dont want this to happen on the first date-selection pages,
     * but only once, passengers are added)
     *
     */
    const isSyncOffers = ref(false);

    /**
     * Reload offers
     * ---------------------------------------------------
     * If any of the product specification changes, reload the offer
     * - only for products where something changed (hash), we
     *   dont want to constantly reload everything all the time
     * - only for main products, we dont want to reload upsell offers (this happens on saving upsell)
     * - only if sync is active. why? we dont want this to happen on every page
     */
    function reloadOffers(newValue: ProductHash, oldValue: ProductHash) {
        Object.entries(newValue).forEach(([k, hash]) => {
            const key = k as ProductKey;
            const product = productsMain.value[key];
            const somethingChanged = oldValue[key] !== hash;

            if (somethingChanged
                && isSyncOffers.value
                && !product?.isUpsell
            ) {
                loadOffer(key);
            }
        });
    };
    useWatchOnReady(hashedProducts, reloadOffers);

    /**
     * Load offer for specific product
     * ---------------------------------------------------
     *
     * Updates offer for a product. If there are no passengers, the offer is removed.
     * Based on the product type, the offer is loaded from the API differently.
     *
     */
    async function loadOffer(key: ProductKey) {
        try {
            isLoading.value = true;
            const product = productsMain.value[key];
            if (!product) {
                return false;
            }
            const passengers = passengerStore.passengers.filter(passenger => product.passengerRefs.includes(passenger.ref));

            // If there are no passengers, remove the offer, as offers without passengers have no meaning
            // Only for products that do have passengers to begin with (why? e.g. hotel doesnt have passengers
            // but rooms and we dont want to delete the offer because of that)
            if (!passengers.length && !product.config.disablePassengers) {
                product.offer = undefined;
                return false;
            }

            if (product.config.type === 'activity') {
                ({ data: product.offer } = await apiLoadActivityOffer(product as ProductActivity, passengers));
            }
            if (product.config.type === 'p2p') {
                ({ data: product.offer } = await apiLoadFinalTripOffer(product as ProductP2P, passengers));
            }
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * E-commerce tracking: T2, T3
     * ------------------------------------------------------------------------------------------
     */
    const { analyticsTrackOfferChanges } = useOfferFlowAnalytics();

    // Watch any changes to offers and track analytics
    useWatchOnReady(offers, analyticsTrackOfferChanges, { deep: true });

    return {
        offers,
        offerCurrent,
        isSyncOffers,
        loadOffer,
    };
}
