/**
 * Extracts an p2p offer from a given offer (from an /offers request)
 *
 * @param offer The offer containing the p2p
 * @returns The p2p offer
 */
export function transformOfferP2P(
    offer: ApiSchema['Offer'],
    productConfig: ProductConfigP2P,
): OfferP2P | undefined {
    const passengerRefs = offer.passengerRefs;
    const tripIds = offer.tripCoverage?.coveredTripIds ?? [];
    const isFirstClass = offer.admissionOfferParts?.every(aop => aop.travelClass === 'FIRST') ?? false;
    const isDiscounted = false;
    const {
        hasEPR,
        isEPRRequired,
        isEPRAvailable,
        offerParts,
        eprLegIds,
    } = transformOfferReservations(offer);

    return {
        offerId: offer.offerId,
        type: 'p2p',
        tripIds,
        passengerRefs,
        offerParts,
        hasEPR,
        isEPRAvailable,
        isEPRRequired,
        isFirstClass,
        isDiscounted,
        eprLegIds,
    } satisfies OfferP2P;
}

/**
 *
 */
function transformOfferReservations(offer: ApiSchema['Offer']) {
    let hasEPR: boolean | 'only';
    let isEPRRequired: boolean;
    let isEPRAvailable: boolean;
    let admissionOfferParts: OfferPartAdmission[];
    let reservationOfferParts: OfferPartReservation[];
    let eprLegIds: string[];

    // EPR Only
    if (!offer.admissionOfferParts?.length && offer.reservationOfferParts?.length) {
        isEPRRequired = true;
        hasEPR = 'only';
        isEPRAvailable = true;
        admissionOfferParts = [];
        reservationOfferParts = offer.reservationOfferParts?.map(rop => transformOfferPartReservation(rop)) ?? [];
        eprLegIds = offer.reservationOfferParts?.map(rop => rop.tripCoverage?.coveredLegIds ?? []).flat();
    }
    // Has admissionOfferParts and OPTIONAL reservationOfferParts
    else if (offer.reservationOfferParts?.length) {
        isEPRRequired = false;
        hasEPR = true;
        isEPRAvailable = true;
        admissionOfferParts = offer.admissionOfferParts?.map(aop => transformOfferPartAdmission(aop)) ?? [];
        reservationOfferParts = offer.reservationOfferParts?.map(rop => transformOfferPartReservation(rop)) ?? [];
        eprLegIds = offer.reservationOfferParts?.map(rop => rop.tripCoverage?.coveredLegIds ?? []).flat();
    }
    // Has MANDATORY reservationOfferParts
    else if (offer.admissionOfferParts?.some(a => a.isReservationRequired)) {
        isEPRRequired = true;
        hasEPR = true;
        isEPRAvailable = true;
        admissionOfferParts = offer.admissionOfferParts?.map(aop => transformOfferPartAdmission(aop)) ?? [];
        reservationOfferParts = [];
        eprLegIds = offer.admissionOfferParts?.flatMap(aop => aop.includedReservations ?? []).map(rop => rop.tripCoverage?.coveredLegIds ?? []).flat();
    }
    // Has NO reservationOfferParts (only admissionOfferParts)
    else {
        isEPRRequired = false;
        hasEPR = false;
        isEPRAvailable = false;
        admissionOfferParts = offer.admissionOfferParts?.map(aop => transformOfferPartAdmission(aop)) ?? [];
        reservationOfferParts = [];
        eprLegIds = [];
    }

    const offerParts = [
        ...admissionOfferParts,
        ...reservationOfferParts,
    ];

    return {
        hasEPR,
        isEPRRequired,
        isEPRAvailable,
        offerParts,
        eprLegIds,
    };
}

/**
 *
 */
function transformOfferPartReservation(rop: ApiSchema['ReservationOfferPart']): OfferPartReservation {
    return {
        id: rop.id,
        type: 'reservation',
        name: rop.summary ?? 'Reservation',
        price: rop.price,
        passengerRefs: rop.passengerRefs,
    };
}

/**
 *
 */
function transformOfferPartAdmission(aop: ApiSchema['AdmissionOfferPart']): OfferPartAdmission {
    return {
        type: 'admission',
        name: aop.summary ?? '',
        price: aop.price,
        passengerRefs: aop.passengerRefs,
        isEPRRequired: aop.isReservationRequired ?? false,
        reservationOfferParts: aop.includedReservations?.map(rop => transformOfferPartReservation(rop)) ?? [],
    };
}
