import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';

/**
 * Stay Durations
 * ------------------------------------------------------------------------------------------
 *
 * Show possible, selectable stay durations between trip up and trip down (e.g. 1h, 1.5h, 2h, ...)
 */
export default function useStayDuration(
    tripsDown: Ref<Trip[]>,

) {
    const shopStore = useShopStore();
    const product = computed(() => shopStore.productCurrentP2P);

    // Currently selected stay duration
    const stayDuration = ref<StayDuration>();

    // The minimum possible stay duration (e.g. 30 minutes)
    // stored as date
    const stayDurationMin = computed(() => {
        if (!product.value?.tripUp) {
            return undefined;
        }
        const minutes = product.value?.config.stayDurationMin;
        return addMinutes(product.value?.tripUp.arrivalTime, minutes);
    });

    // The maximum possible stay duration (e.g. 4.5h)
    // the maximum stay duration is the time between the LAST trip down and the trip up
    // stored as date
    const stayDurationMax = computed(() => {
        if (!product.value?.tripUp || !tripsDown.value.length) {
            return undefined;
        }

        const lastConnection = tripsDown.value[tripsDown.value.length - 1]!;
        const iteration = product.value?.config.stayDurationIteration;
        const minutes = Math.floor(differenceInMinutes(lastConnection.departureTime, product.value?.tripUp?.arrivalTime) / iteration) * iteration;
        return addMinutes(product.value?.tripUp.arrivalTime, minutes);
    });

    // Final list of selectable stay durations
    // The stay duration also already keeps a copy of the matching trips down
    // (trips that fall inbetween the stay duration: e.g. departing between 14:30 and 15:00
    const stayDurations = computed(() => {
        if (!stayDurationMin.value || !stayDurationMax.value)
            return [];
        const stayDurations: StayDuration[] = [];
        let current = stayDurationMin.value;

        // adding stay durations in iteration steps (e.g. 1h, 1.5h, 2h, ...)
        while (isBeforeOrEqual(current, stayDurationMax.value)) {
            const minutes = differenceInMinutes(current, stayDurationMin.value) + (product.value?.config.stayDurationMin ?? 0);
            const upperLimit = addMinutes(current, product.value?.config.stayDurationIteration ?? 0);

            // Store all trips that fall inbetween the stay duration to directly display
            const trips = tripsDown.value.filter(trip =>
                isAfterOrEqual(trip.departureTime, current)
                && isBefore(trip.departureTime, upperLimit));
            const isDisabled = trips.every(trip => trip.isDisabled);

            stayDurations.push({
                minutes,
                date: current,
                isPopular: minutes === product.value?.config.stayDurationPopular,
                isDisabled,
                trips,
            });
            current = addMinutes(current, product.value?.config.stayDurationIteration ?? 0);
        }
        return stayDurations;
    });

    return {
        stayDuration,
        stayDurations,
    };
}
