/**
 * Load Trips
 * ----------------------------
 *
 * Functions to load trips
 * Either load up & down at the same time or separated
 */
export default function useLoadTrips(
    tripsUp: Ref<Trip[]>,
    tripsDown: Ref<Trip[]>,
    isEarlierVisible: Ref<boolean>,
    departureTime: Ref<Date | undefined>,
    stayDuration: Ref<StayDuration | undefined>,
    routeVariantUp: Ref<RouteVariant | undefined>,
    routeVariantDown: Ref<RouteVariant | undefined>,

) {
    const passengerStore = usePassengerStore();
    const shopStore = useShopStore();
    const { isLoading } = useLoading();
    const product = computed(() => shopStore.productCurrentP2P);

    /**
     * Load trips for:
     * - Trip up
     * - Trip down if its a roundtrip
     *
     * Load trips in parallel to speed up the process
     */
    async function loadTrips() {
        try {
            isLoading.value = true;
            // Reset
            resetUp();
            resetDown();
            // Scroll back to top of page
            scrollTo({ top: 0, behavior: 'smooth' });
            // Only fetch down trips if its a roundtrip
            const isRoundtrip = product.value?.isRoundtrip;
            const [tripsUpResponse, tripsDownResponse] = await Promise.all([
                fetchTripsUp(),
                isRoundtrip ? fetchTripsDown() : Promise.resolve({ data: [] }),
            ]);
            tripsUp.value = tripsUpResponse.data;
            tripsDown.value = tripsDownResponse.data;
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Load trips for the up direction (ORIGIN -> DESTINATION), only trips up
     * Function is for standalone loading, including controlling loading state and error handling
     */
    async function loadTripsUp() {
        try {
            isLoading.value = true;
            // Reset
            resetUp();
            // Scroll back to top of page
            scrollTo({ top: 0, behavior: 'smooth' });
            ({ data: tripsUp.value } = await fetchTripsUp());
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Load trips for the down direction (DESTINATION -> ORIGIN), only trips down
     * Function is for standalone loading, including controlling loading state and error handling
     */
    async function loadTripsDown() {
        try {
            isLoading.value = true;
            // Reset
            resetDown();
            // Scroll back to top of page
            scrollTo({ top: 0, behavior: 'smooth' });
            ({ data: tripsDown.value } = await fetchTripsDown());
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Fetch trips (and offers) for the up direction (ORIGIN -> DESTINATION)
     * Only fetches the data, without controlling loading state or error handling
     */
    async function fetchTripsUp() {
        if (!product.value)
            return { data: [] };
        const passengers = passengerStore.passengers.length ? passengerStore.passengers : passengerStore.passengerCount;
        const direction = 'up';
        const routeVariant = routeVariantUp.value;

        return apiLoadSingleTripOffers(
            product.value,
            passengers,
            direction,
            routeVariant,
        );
    }

    /**
     * Load trips (and offers) for the down direction (DESTINATION -> ORIGIN)
     * Only fetches the data, without controlling loading state or error handling
     */
    async function fetchTripsDown() {
        if (!product.value)
            return { data: [] };
        const passengers = passengerStore.passengers.length ? passengerStore.passengers : passengerStore.passengerCount;
        const direction = 'down';
        const routeVariant = routeVariantDown.value;

        return apiLoadSingleTripOffers(
            product.value,
            passengers,
            direction,
            routeVariant,
        );
    }

    /**
     * Reset data related to trip up
     */
    function resetUp() {
        tripsUp.value = [];
        product.value && (product.value.tripUp = undefined);
        departureTime.value = undefined;
        isEarlierVisible.value = false;
    }

    /**
     * Reset data related to trip down
     */
    function resetDown() {
        tripsDown.value = [];
        product.value && (product.value.tripDown = undefined);
        stayDuration.value = undefined;
    }

    return {
        loadTrips,
        loadTripsUp,
        loadTripsDown,
    };
}
