/**
 * Provides functions to track ecommerce events in the offerflow domain (based on offerflow/ shop store)
 */
export function useOfferFlowAnalytics() {
    const passengerStore = usePassengerStore();
    const shopStore = useShopStore();

    /**
     * Tracks Ecommerce Events in the offer flow
     * (currently without any additional data)
     */
    function analyticsTrackOfferFlowEvent(eventName: EcommerceEventName) {
        analyticsTrackEcommerce(eventName);
    }

    /**
     * Tracks changes to the shopStore offers.
     * - New offer added
     * - New offerPart added to a offer
     * - Offer removed
     * - OfferPart removed from a offer
     *
     * Each offer part is separately tracked as "add_to_cart" or "remove_from_cart" event
     * @param newVal
     * @param oldVal
     */
    function analyticsTrackOfferChanges(newVal: Offers, oldVal: Offers) {
        for (const [key, oldOffer] of Object.entries(oldVal)) {
            const productKey = key as ProductKey;
            if (!newVal[productKey]) {
                trackRemoveOffer(productKey, oldOffer); // Whole offer removed
            }
        }

        for (const [key, newOffer] of Object.entries(newVal)) {
            const productKey = key as ProductKey;
            const oldOffer = oldVal[productKey];

            if (!oldOffer) {
                trackAddOffer(productKey, newOffer); // Whole offer added
                continue;
            }

            newOffer.offerParts.forEach((offerPart, index) => {
                if (!oldOffer.offerParts[index]) {
                    trackAddOfferPart(productKey, offerPart); // OfferPart added
                }
            });

            oldOffer.offerParts.forEach((offerPart, index) => {
                if (!newOffer.offerParts[index]) {
                    trackRemoveOfferPart(productKey, offerPart); // OfferPart removed
                }
            });
        }
    }

    /**
     * New offer added, track every offer part
     */
    function trackAddOffer(key: ProductKey, offer: Offer) {
        offer.offerParts.forEach((offerPart) => {
            trackAddOfferPart(key, offerPart);
        });
    }

    /**
     * Offer removed, track every offer part
     */
    function trackRemoveOffer(key: ProductKey, offer: Offer) {
        offer.offerParts.forEach((offerPart) => {
            trackRemoveOfferPart(key, offerPart);
        });
    }

    /**
     * Track adding an offer part to an offer
     * (Equals adding a product to the cart)
     */
    function trackAddOfferPart(key: ProductKey, offerPart: OfferPart) {
        const product = shopStore.productsMain[key];
        const passenger = passengerStore.passengers.find(passenger => offerPart?.passengerRefs.includes(passenger.ref));
        const item = analyticsGetEcommerceItem({ product, offerPart, passenger });

        const ecommerceData = {
            currency: offerPart.price.currency,
            value: offerPart.price.amount / 100,
            items: [
                item,
            ],
        };
        analyticsTrackEcommerce('add_to_cart', ecommerceData);
    }

    /**
     * Track removing an offer part from an offer
     * (Equals removing an item from the cart)
     */
    function trackRemoveOfferPart(key: ProductKey, offerPart: OfferPart) {
        const product = shopStore.productsMain[key];

        // Skip tracking if store has been reset
        if (!product) {
            return;
        }

        const passenger = passengerStore.passengers.find(passenger => offerPart?.passengerRefs.includes(passenger.ref));
        const item = analyticsGetEcommerceItem({ product, offerPart, passenger });

        const ecommerceData = {
            currency: offerPart.price.currency,
            value: offerPart.price.amount / 100,
            items: [
                item,
            ],
        };
        analyticsTrackEcommerce('remove_from_cart', ecommerceData);
    }

    return {
        analyticsTrackOfferChanges,
        analyticsTrackOfferFlowEvent,
    };
}
