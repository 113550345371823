type ResultType = ActivityInfo[];
type ApiResponseType = ApiSchema['ActivitiesResponse'];
interface ParamsType { }

/**
 * Loads the availabilities for a specific activity and date range.
 */
export default async function apiLoadActivities(categoryId: string): Promise<ApiResult<ResultType>> {
    try {
        return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            '/activities',

            // Request body
            { categories: categoryId },

            // Transform result
            result => (result.activities ?? []).map(activity => ({
                id: activity.id,
                title: activity.title ?? '',
                categories: activity.categories ?? [],
                salesPeriods: (activity.salesPeriods ?? []).map(period => ({
                    title: period.title ?? '',
                    sellableFrom: period.sellableFrom,
                    sellableTo: period.sellableTo,
                })),
                details: activity.details ?? {},
            })),
        );
    }
    catch (error) {
        handleError(error, { categoryId }, false);
        return {
            ok: false,
            warnings: [],
            data: [],
        };
    }
}
