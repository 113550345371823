import { alphabetical, sort } from 'radash';

export function filterByLocations(hotels: Hotel[], locations: FilterGroup['options']) {
    const postCodes = configHotelLocations.filter(config => locations.includes(config.key)).flatMap(l => l.postCodes);
    return hotels.filter(hotel => hotel.address?.postCode && postCodes?.includes(hotel.address?.postCode));
}

export function filterByRatings(hotels: Hotel[], ratings: FilterGroup['options']) {
    return hotels.filter(hotel => ratings.flat().includes(hotel.rating?.starRating?.rating ?? null));
}

export function filterByCategories(hotels: Hotel[], categories: FilterGroup['options']) {
    return hotels.filter(hotel => categories.every(category => hotel.categories.includes(category as string)));
}

export function filterHotels(hotels: Hotel[], selectedFilterGroups: FilterGroup[]) {
    const locations = selectedFilterGroups.find(fg => fg.key === 'location')?.options as FilterGroup['options'] ?? [];
    const ratings = selectedFilterGroups.find(fg => fg.key === 'rating')?.options as FilterGroup['options'] ?? [];
    const categories = selectedFilterGroups.find(fg => fg.key === 'category')?.options as FilterGroup['options'] ?? [];

    let filteredHotels = hotels;

    if (locations.length > 0) {
        filteredHotels = filterByLocations(filteredHotels, locations);
    }

    if (ratings.length > 0) {
        filteredHotels = filterByRatings(filteredHotels, ratings);
    }

    if (categories.length > 0) {
        filteredHotels = filterByCategories(filteredHotels, categories);
    }

    return filteredHotels;
}

export function sortHotels(hotels: Hotel[], sorting: HotelSorting) {
    const sorts = {
        name: () => alphabetical(hotels, (h: Hotel) => h.name, sorting.orderDirection),
        price: () => sort(hotels, (h: Hotel) => h.pricePerNightFrom?.amount ?? 0, sorting.orderDirection === 'desc'),
        review: () => sort(hotels, (h: Hotel) => h.rating?.reviewRating?.summaryRating?.rating ?? 0, sorting.orderDirection === 'desc'),
    };
    return sorts[sorting.orderByKey]();
};
