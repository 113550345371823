import { isEqual } from 'ohash';
import { sift } from 'radash';

/**
 * Hotel Store
 * ----------------------------
 *
 */

export const useHotelStore = defineStore('hotel', () => {
    const passengerStore = usePassengerStore();
    const shopStore = useShopStore();

    /**
     * Product Data of hotel
     * ------------------------------------------------------------------------------------------
     */
    const product = computed(() => shopStore.productCurrentHotel);

    /**
     * Basic Hotel Data
     * ------------------------------------------------------------------------------------------
     */
    const hotels = ref<Hotel[]>([]);

    /**
     * Room Offers
     * ------------------------------------------------------------------------------------------
     */
    const roomOffers = ref<OfferHotel[]>([]);

    /**
     * Filtering / Sorting
     * ------------------------------------------------------------------------------------------
     */
    const availableFilterGroups = ref<FilterGroup[]>([]);
    const selectedFilterGroups = ref<FilterGroup[]>([]);
    const selectedSorting = ref<HotelSorting>({
        orderByKey: 'review',
        orderDirection: 'desc',
    });
    const filteredHotels = computed(() => sortHotels(filterHotels(hotels.value, selectedFilterGroups.value), selectedSorting.value));

    /**
     * Selected Hotel
     * ------------------------------------------------------------------------------------------
     */
    const selectedHotel = ref<Hotel>();
    const selectedHotelAvailableRooms = computed(() => selectedHotel.value?.rooms ?? []);
    const selectedHotelRoomOffers = computed(() => roomOffers.value.filter(offer => offer.offerParts.every(part => part.accommodationRef === selectedHotel.value?.id)));

    /**
     * Whenever the selected hotel changes, reset the selected rooms,
     * because the rooms are not valid anymore.
     */
    useWatchOnReady(selectedHotel, onSelectedHotelChanged);
    function onSelectedHotelChanged(newVal?: Hotel, oldVal?: Hotel) {
        if (newVal?.id !== oldVal?.id) {
            product.value?.rooms.forEach((roomSlot) => {
                roomSlot.roomRef = null;
            });
        }
    }
    /**
     * Selected hotel offers
     */
    const selectedOffers = computed(() => {
        // Get the offers per each selected room
        const offers = product.value?.rooms.map((room) => {
            return roomOffers.value.find((offer) => {
                return offer.offerParts.some((op) => {
                    const matchesRoom = op.roomRef === room.roomRef;
                    const matchesPassengers = isEqual(op.passengerRefs, room.passengerRefs.map(p => p.externalRef).sort());
                    return matchesRoom && matchesPassengers;
                });
            });
        }) ?? [];
        // Filter out undefined offers
        return sift(offers);
    });

    /**
     * Load hotel list
     * ------------------------------------------------------------------------------------------
     */
    const { isLoading } = useLoading();
    async function loadHotelList(forceFresh = false) {
        if (!product.value) {
            return false;
        }

        if (!forceFresh && hotels.value.length > 0) {
            return true;
        }

        isLoading.value = true;
        hotels.value = [];
        availableFilterGroups.value = [];

        const { ok, data } = await apiLoadHotels(product.value, passengerStore.passengers);
        if (ok) {
            hotels.value = data.hotels;
            availableFilterGroups.value = data.availableFilterGroups;
            roomOffers.value = data.offers;
        }

        isLoading.value = false;
        return ok;
    }

    /**
     * Resetting
     * ------------------------------------------------------------------------------------------
     */

    /**
     * Reset hotels when any of the determining values change
     * - If you change vacation dates, reset
     * - If you change room/passenger constellation, reset
     */
    function resetHotelsOnChange(newVal: any, oldVal: any) {
        // only reset when both old/new exists. why? Because we dont want to reset
        // hotel list if we enter/leave hotel flow (go/go to undefined)
        if (newVal && oldVal) {
            resetHotels();
        }
    }
    useWatchOnReady(() => product.value?.dateFrom, resetHotelsOnChange);
    useWatchOnReady(() => product.value?.dateUntil, resetHotelsOnChange);
    useWatchOnReady(() => product.value?.rooms, resetHotelsOnChange);

    function reset() {
        resetHotels();
    }

    function resetHotels() {
        hotels.value = [];
        roomOffers.value = [];
        availableFilterGroups.value = [];
        selectedFilterGroups.value = [];
        selectedSorting.value = {
            orderByKey: 'review',
            orderDirection: 'desc',
        };
        selectedHotel.value = undefined;
    }

    return {
        product,

        loadHotelList,
        hotels,

        availableFilterGroups,
        selectedFilterGroups,
        filteredHotels,
        selectedSorting,

        selectedHotel,
        selectedHotelAvailableRooms,
        selectedHotelRoomOffers,

        roomOffers,
        selectedOffers,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
