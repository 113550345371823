type ResultType = AvailableActivityOffer[];
type ApiResponseType = ApiSchema['OfferCollectionResponse'];
type ParamsType = ApiSchema['OfferCollectionRequest'];

/**
 * Loads the available offers for a specific activity, starting from a specific date.
 * The number of returned offers equals the number of possible multi-day activities.
 * e.g. Offer 1 = 1 day, Offer 2 = 2 days, Offer 3 = 3 days, etc.
 */
export default async function apiLoadAvailableActivityOffers(activityId: string, dateFrom: Date): Promise<ApiResult<ResultType>> {
    try {
        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/offers',

            // Request body
            {
                offerSearchCriteria: {
                    validFrom: formatDateTimeISO(dateFrom),
                    activityIds: [
                        activityId,
                    ],
                },
                anonymousPassengerSpecifications: [
                    {
                        externalRef: 'pax1',
                        type: 'PERSON',
                        age: 30,
                        cards: [],
                    },
                ],
            },

            // Transform result
            (response: ApiResponseType) => response.offers?.map(offer => ({
                offerId: offer.offerId,
                validFrom: zurichDate(offer.activityOfferParts?.[0]?.validFrom),
                validUntil: zurichDate(offer.activityOfferParts?.[0]?.validUntil),
                duration: offer.activityOfferParts?.[0]?.duration ?? '',
                availableFulfillmentOptions: offer.activityOfferParts?.[0]?.availableFulfillmentOptions.map(afo => ({
                    type: afo.type,
                    media: afo.media,
                })) ?? [],
            } satisfies AvailableActivityOffer)) ?? [],
        );
    }
    catch (error) {
        handleError(error, { activityId, dateFrom }, false);
        return {
            ok: false,
            warnings: [],
            data: [],
        };
    }
}
