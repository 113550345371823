import { transformAvailableFilterOptions } from '~upselling/api/transformers/hotel/transformAvailableFilterOptions';
import { transformHotels } from '~upselling/api/transformers/hotel/transformHotels';

interface ResultType {
    hotels: Hotel[];
    availableFilterGroups: FilterGroup[];
    offers: OfferHotel[];
}
type ApiResponseType = ApiSchema['OfferCollectionResponse'];
type ParamsType = ApiSchema['OfferCollectionRequest'];

/**
 *
 */
export default async function apiLoadHotels(product: ProductHotel, passengers: Passenger[]): Promise<ApiResult<ResultType>> {
    try {
        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/offers',

            // Request body
            {
                offerSearchCriteria: {
                    validFrom: formatDateTimeISO(product.dateFrom),
                    validUntil: formatDateTimeISO(product.dateUntil),
                    accommodationSearchCriteria: {
                        passengersToRoomMapping: product.rooms.map(room => ({ passengerRefs: room.passengerRefs.map(pr => ({ externalRef: pr.externalRef })) })),
                    },
                },
                anonymousPassengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards ?? [],
                })),
            },

            // Transform result
            (response: ApiResponseType) => ({
                hotels: transformHotels(response),
                offers: transformOffers(response),
                availableFilterGroups: transformAvailableFilterOptions(response),
            }),

            {
                timeout: 60000,
            },
        );
    }
    catch (error) {
        handleError(error, {
            product,
            passengers,
        });
        return {
            ok: false,
            warnings: [],
            data: {
                hotels: [],
                availableFilterGroups: [],
                offers: [],

            },
        };
    }
}

/**
 *
 */
function transformOffers(response: ApiResponseType) {
    return response.offers?.flatMap(offer => transformOfferHotel(offer) ?? []) ?? [];
}
