type ResultType = OfferP2P | undefined;
type ApiResponseType = ApiSchema['OfferCollectionResponse'];
type ParamsType = ApiSchema['OfferCollectionRequest'];

/**
 *
 */
export default async function apiLoadFinalTripOffer(
    product: ProductP2P,
    passengers: Passenger[],

): Promise<ApiResult<ResultType>> {
    try {
        const requestedOfferParts: ApiSchema['OfferPartType'][] = [];
        if (product.config.hasEPR === 'only') {
            requestedOfferParts.push('RESERVATION');
        }
        else if (product.config.hasEPR === true) {
            requestedOfferParts.push('ADMISSION');
            requestedOfferParts.push('RESERVATION');
        }
        else {
            requestedOfferParts.push('ADMISSION');
        }

        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/offers',

            // Request body
            {
                tripIds: [
                    ...(product.tripUp ? [product.tripUp.id] : []),
                    ...(product.tripDown ? [product.tripDown.id] : []),
                ],
                offerSearchCriteria: {
                    requestedOfferParts,
                },
                anonymousPassengerSpecifications: getAnonymousPassengerSpecifications(passengers),
            },

            // Transform result
            (response: ApiResponseType): ResultType => response.offers?.[0]
                ? transformOfferP2P(response.offers?.[0], product.config)
                : undefined,
        );
    }
    catch (error) {
        handleError(error, { product, passengers });
        return {
            ok: false,
            warnings: [],
            data: undefined,
        };
    }
}

function getAnonymousPassengerSpecifications(passengers: Passenger[]): ApiSchema['AnonymousPassengerSpecification'][] {
    return passengers.map(p => ({
        externalRef: p.ref,
        type: 'PERSON',
        dateOfBirth: formatDateTimeISO(p.dateOfBirth),
        cards: p.reductionCards,
    }));
}
