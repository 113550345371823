type ResultType = Quote | undefined;
type ApiResponseType = ApiSchema['Quote'];
type ParamsType = ApiSchema['BookingRequest'];

/**
 * Loads the quote for the given offers and passengers.
 * The quote contains the final price for all given offers combined.
 */
export default async function apiLoadQuote(offers: Offer[], passengers: Passenger[]): Promise<ApiResult<ResultType>> {
    try {
        const optionalReservationSelections = offers
            .flatMap(o => o.offerParts as OfferPart[])
            .filter(op => op.type === 'reservation')
            .map(op => ({ reservationId: op.id })) ?? [];

        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/quotes',

            // Request body
            {
                offers: offers.map(o => ({
                    offerId: o.offerId,
                    passengerRefs: o.passengerRefs,
                    optionalReservationSelections,
                })),
                passengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards ?? [],
                })),
            },
            // Transform result
            (response: ApiResponseType) => transformQuote(response),
        );
    }
    catch (error) {
        handleError(error, { offers, passengers });
        return {
            ok: false,
            warnings: [],
            data: undefined,
        };
    }
}

/**
 * Transform quote
 */
function transformQuote(response: ApiResponseType) {
    return {
        price: response.price,
    };
}
