import type { WatchCallback, WatchOptions, WatchSource } from 'vue';

/**
 * When using watch() inside pinia store while using Pinia persist plugin, the watch() callback will be correctfully called right away,
 * when the store is hydrated from localStorage, because hydrating is also a "normal" change.
 * This deferred watcher will only start watching, once the Nuxt app is ready (after pinia hydration).
 */
export default function useWatchOnReady(sources: WatchSource | WatchSource[], callback: WatchCallback, options?: WatchOptions) {
    const { pause, resume } = watchPausable(sources, callback, options);
    pause();
    onNuxtReady(async () => resume());
}
