import { addMinutes, startOfDay } from 'date-fns';

/**
 * Show Earlier Connections in the  morning (before default start (e.g. 06:30)
 * ------------------------------------------------------------------------------------------
 */
export default function useEarlier(
    tripsUp: Ref<Trip[]>,

) {
    const shopStore = useShopStore();
    const product = computed(() => shopStore.productCurrentP2P);

    // By default, the app only shows trips leaving after a default minimum time (e.g. 06:30), because we dont want to
    // show weird night trains at 02:30 as the first option. However, we want to give the user the option to see earlier
    // connections.
    const isEarlierVisible = ref(false);

    // Index of the earliest trip to show after the default minimum start time. e.g. if there are 3 trips before 06:30, the
    // index is 3 (0, 1, 2, **3**)
    // Used to know trip list to show
    const indexEarliest = computed(() => {
        if (!tripsUp.value.length)
            return 0;
        const timeFirst = tripsUp.value[0]!.departureTime;
        const timeDefaultEarliest = addMinutes(startOfDay(timeFirst), product.value?.config.departureTimeDefaultMin ?? 0);
        const time = isEarlierVisible.value ? timeFirst : timeDefaultEarliest;
        return tripsUp.value.findIndex(trip => isAfterOrEqual(trip.departureTime, time)) ?? 0;
    });

    // Whether the "Show Earlier" button is visible (only when earlier trips available)
    const isEarlierButtonVisible = computed(() => indexEarliest.value > 0);

    return {
        isEarlierVisible,
        indexEarliest,
        isEarlierButtonVisible,
    };
}
