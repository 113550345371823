import { endOfDay, max } from 'date-fns';

type ResultType = Trip[];
type ApiResponseType = ApiSchema['OfferCollectionResponse'];
type ParamsType = ApiSchema['OfferCollectionRequest'];

/**
 *
 */
export default async function apiLoadSingleTripOffers(
    product: ProductP2P,
    passengers: Passenger[] | number = 1,
    direction: 'up' | 'down',
    routeVariant?: RouteVariant,
): Promise<ApiResult<ResultType>> {
    try {
        if (!product.origin || !product.destination) {
            throw new Error('Origin or destination missing');
        }
        const origin = direction === 'up' ? product.origin : product.destination;
        const destination = direction === 'up' ? product.destination : product.origin;
        const departureTime = product.dateFrom
            ? max([product.dateFrom, zurichDate()])
            : zurichDate();

        const requestedOfferParts: ApiSchema['OfferPartType'][] = [];
        if (product.config.hasEPR === 'only') {
            requestedOfferParts.push('RESERVATION');
        }
        else if (product.config.hasEPR === true) {
            requestedOfferParts.push('ADMISSION');
            requestedOfferParts.push('RESERVATION');
        }
        else {
            requestedOfferParts.push('ADMISSION');
        }

        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
        // return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            // 'data/_test/trips.json',
            '/offers',

            // Request body
            {
                tripSearchCriteria: {
                    origin: {
                        objectType: 'StopPlaceRef',
                        // @ts-expect-error TODO: Field exists in schema but is not typed
                        stopPlaceRef: origin!.ref,
                    },
                    destination: {
                        objectType: 'StopPlaceRef',
                        // @ts-expect-error TODO: Field exists in schema but is not typed
                        stopPlaceRef: destination.ref,
                    },
                    // @ts-expect-error TODO: Falsely typed default values required
                    parameters: routeVariant
                        ? { dataFilter: { routeFilter: { namedRoute: routeVariant } } }
                        : undefined,
                    departureTime: formatDateTimeISO(departureTime),
                    departureTimeUntil: formatDateTimeISO(product.dateFrom && endOfDay(product.dateFrom)),
                },
                offerSearchCriteria: {
                    requestedOfferParts,
                },
                anonymousPassengerSpecifications: getAnonymousPassengerSpecifications(passengers),
            },

            // Transform result
            (response: ApiResponseType): ResultType => {
                const offers = transformOffers(response, product.config);
                const trips = transformTrips(response, offers);
                return trips;
            },
            // {
            //     baseURL: 'http://localhost:3000',
            // },
        );
    }
    catch (error) {
        handleError(error, { product, passengers, origin, routeVariant });
        return {
            ok: false,
            warnings: [],
            data: [],
        };
    }
}

/**
 *
 */
function transformOffers(response: ApiResponseType, productConfig: ProductConfigP2P): OfferP2P[] {
    return response.offers?.flatMap(offer => transformOfferP2P(offer, productConfig) ?? []) ?? [];
}

/**
 *
 */
function transformTrips(response: ApiResponseType, offers: OfferP2P[]): Trip[] {
    return response.trips?.flatMap(trip => transformTrip(trip, offers) ?? []) ?? [];
}

/**
 *
 */
function getAnonymousPassengerSpecifications(passengers: Passenger[] | number): ApiSchema['AnonymousPassengerSpecification'][] {
    if (Array.isArray(passengers)) {
        return passengers.map(p => ({
            externalRef: p.ref,
            type: 'PERSON',
            dateOfBirth: formatDateTimeISO(p.dateOfBirth),
            cards: p.reductionCards,
        }));
    }
    else {
        return Array.from({ length: passengers }, (_, i) => ({
            externalRef: `anonymous-${i}`,
            type: 'PERSON',
            age: 30,
            cards: [],
        }));
    }
}
