import { differenceInDays } from 'date-fns';

/**
 * Extracts an hotel offer from a given offer (from an /offers request)
 *
 * @param offer The offer containing the hotel
 * @returns The hotel offer
 */
export function transformOfferHotel(
    offer: ApiSchema['Offer'],
): OfferHotel | undefined {
    const passengerRefs = offer.passengerRefs;

    const offerParts = offer.accommodationOfferParts?.map((aop) => {
        return {
            type: 'hotel',
            name: aop.summary ?? '',
            roomRef: aop.roomRef ?? '',
            accommodationRef: aop.accommodationRef ?? '',
            price: aop.price,
            includedMeals: aop.includedMeals ?? { breakfast: false, lunch: false, dinner: false },
            refundDeadline: zurichDate(aop.cancellationDeadline),
            passengerRefs: aop.passengerRefs,
            nights: aop.validUntil ? Math.abs(differenceInDays(aop.validFrom, aop.validUntil)) : 1,
        } satisfies OfferPartHotel;
    }) ?? [];

    return {
        offerId: offer.offerId,
        type: 'hotel',
        passengerRefs,
        offerParts,
    } satisfies OfferHotel;
}
