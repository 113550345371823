export default [
    {
        key: 'grindelwald',
        name: 'Grindelwald',
        postCodes: ['3818'],
    },
    {
        key: 'muerren',
        name: 'Mürren',
        postCodes: ['3825'],
    },
    {
        key: 'lauterbrunnen',
        name: 'Lauterbrunnen',
        postCodes: ['3822'],
    },
    {
        key: 'wengen',
        name: 'Wengen',
        postCodes: ['3823'],
    },
    {
        key: 'wilderswil',
        name: 'Wilderswil',
        postCodes: ['3812'],
    },
    {
        key: 'interlaken',
        name: 'Interlaken',
        postCodes: ['3800', '3803'],
    },
];
