import { hash } from 'ohash';

type ProductHash = Record<ProductKey, string>;

/**
 * Products
 * ----------------------------
 *
 * Manages products for main/upsell flow
 *
 */
export default function useProducts() {
    const flowStore = useFlowStore();
    const passengerStore = usePassengerStore();

    /**
     * Main (final) prduct list
     * ---------------------------------------------------
     * ProductKey + Product for each product
     * only 1 product of each type can be stored at the same time
     * Products of the main flow are directly edited here
     * Upsell products are copied here, once they are completed
     */
    const productsMain = ref<Products>({});

    /**
     * Products in upsell flow
     * ---------------------------------------------------
     * ProductKey + Product for each product
     * Only 1 upsell product is ever active at the same time, but we use the same structure as for main products
     * So every product offerflow can be configured as "main" or "upsell" and use the same adding mechanisms
     */
    const productsUpsell = ref<Products>({});

    /**
     * Current active flow products (main or upsell)
     * ---------------------------------------------------
     * If you are currently in the main flow: --> main products
     * If you are currently in the upsell flow: --> upsell products
     */
    const productsCurrent = computed<Products>({
        get: () => flowStore.isUpsellFlow ? productsUpsell.value : productsMain.value,
        set: value => (flowStore.isUpsellFlow ? productsUpsell : productsMain).value = value,
    });

    /**
     * Product currently active in main flow
     * ---------------------------------------------------
     * Even if you are in the upsell flow, this is the main product, so you can always access it
     * from the upsell flow. (e.g. in the Hotel flow, you need data from the main "ski" product)
     */
    const productMain = computed<Product | undefined>(() => {
        return flowStore.productConfigMain && productsMain.value[flowStore.productConfigMain?.key];
    });

    /**
     * Product currently active in upsell flow
     * ---------------------------------------------------
     * If you are in the main flow, this is undefined or not relevant
     */
    const productUpsell = computed<Product | undefined>(() => {
        return flowStore.productConfigUpsell && productsUpsell.value[flowStore.productConfigUpsell?.key];
    });

    /**
     * Current active flow product (main or upsell)
     * ---------------------------------------------------
     * If you are currently in the main flow: --> the current main product (eg ski)
     * If you are currently in the upsell flow: --> the current upsell product (eg hotel)
     */
    const productCurrent = computed<Product | undefined>(() => {
        return flowStore.isUpsellFlow ? productUpsell.value : productMain.value;
    });

    /**
     * Current active flow product (typed as ProductHotel)
     */
    const productCurrentHotel = computed(() => productCurrent.value?.config.type === 'hotel'
        ? productCurrent.value as ProductHotel
        : undefined);

    /**
     * Current active flow product (typed as ProductActivity)
     */
    const productCurrentActivity = computed(() => productCurrent.value?.config.type === 'activity'
        ? productCurrent.value as ProductActivity
        : undefined);

    /**
     * Current active flow product (typed as ProductP2P)
     */
    const productCurrentP2P = computed(() => productCurrent.value?.config.type === 'p2p'
        ? productCurrent.value as ProductP2P
        : undefined);

    /**
     *  ProductKey + Hash for each product
     * ---------------------------------------------------
     * Stores a hash for each product, so we can easily watch it and detect changes in
     * any of the product specifications. Every change to a product will change the hash
     * - Exception: the "offer" is excluded from the hash, because we update the offer
     *   based on the hash changes, so we would end up in an infinite loop
     */
    const hashedProducts = computed(() =>
        Object.entries(productsMain.value).reduce<ProductHash>((acc, [key, product]) => {
            acc[key as ProductKey] = hash({ ...product, offer: undefined });
            return acc;
        }, {} as ProductHash),
    );

    /**
     * Add product to current flow (main or upsell)
     * ---------------------------------------------------
     *
     * This is dynamic, so you can use every product flow as main or upsell
     * If you are in the main flow, you add products to "productsMain"
     * If you are in the upsell flow, you add products to "productsUpsell"
     */
    function addProduct(product: Product) {
        product.isUpsell = flowStore.isUpsellFlow;
        productsCurrent.value = {
            ...productsCurrent.value,
            [product.config.key]: product,
        };
    }

    /**
     * Delete product completely
     * ---------------------------------------------------
     * When a product is removed, it is removed from both main and upsell products
     */
    function removeProduct(key: ProductKey) {
        delete productsMain.value[key];
        delete productsUpsell.value[key];
    }

    /**
     * Save upsell (move to main products)
     * ---------------------------------------------------
     * "Saving" an upsell means, that you are done with the upsell flow and you
     * go back to the main flow. The upsell product is then COPIED to the main products and
     * overwrites the previously existing same product in the main flow.
     *
     * Why copy and not move? Because moving it right away may leads to visual glitches, as
     * it happens while leaving the upsell flow and its still visible. Also, you can't really
     * prevent aborting the upsell flow in other ways (e.g. browser back button, etc.). So upsell products
     * lingering around should not be a problem.
     */
    function saveUpsell(key: ProductKey) {
        const copy = deepClone(productsUpsell.value[key]);
        productsMain.value = {
            ...productsMain.value,
            [key]: copy,
        };
    }

    /**
     * Edit upsell (move to upsell products)
     * ---------------------------------------------------
     * If you want to edit an existing upsell product, you would enter the upsell flow
     * from the main flow. The product is then copied to the upsell products.
     *
     * Why? This way, you can safely edit and adjust the upsell product and then
     * confirm your changes when going back to the main flow. Only then, your changes
     * are applied (by copying the edited product back to the main products).
     */
    function editUpsell(key: ProductKey) {
        const copy = deepClone(productsMain.value[key]);
        productsUpsell.value = {
            ...productsUpsell.value,
            [key]: copy,
        };
    }

    /**
     * Delete upsell product
     * ---------------------------------------------------
     */
    function removeProductUpsell(key: ProductKey) {
        delete productsUpsell.value[key];
    }

    /**
     * Remove depending products
     * ---------------------------------------------------
     * Whenever a main product is edited, remove all other products and upsells
     *
     * Why?
     * - There can only be 1 main product, so adding another main product automatically kicks out the old one
     * - If the main product is edit, this has a direct impact on the upsell products. Therefore whenever the
     *   main product is changed, we kick out all upsells. For example you change you ski trip dates from february
     *   to march, obviously your hotel dates from february are not valid anymore.
     */
    function removeDependingProducts(newValue: ProductHash, oldValue: ProductHash) {
        let productsToReset: Product[] = [];

        Object.entries(newValue).forEach(([k, hash]) => {
            const key = k as ProductKey;
            const product = productsMain.value[key];
            const somethingChanged = oldValue[key] !== hash;

            // If something changed on the product and this was not an upsell product
            // (changing upsells does not affect other products)
            if (somethingChanged && !product?.isUpsell) {
                productsToReset = Object.values(productsMain.value).filter(p => p.config.key !== product?.config.key);
            }
        });

        // This products need to be removed, as they may be not valid anymore
        productsToReset.forEach((p) => {
            removeProduct(p.config.key);

            // Reset hotel store if hotel product is removed
            if (p.config.type === 'hotel') {
                useHotelStore().reset();
            }

            // Reset trip store if trip product is removed
            if (p.config.type === 'p2p') {
                useTripStore().reset();
            }

            // Reset activity store if activity product is removed
            if (p.config.type === 'activity') {
                useActivityStore().reset();
            }
        });
    };
    useWatchOnReady(hashedProducts, removeDependingProducts);

    /**
     * Sync passenger updates to products
     * ---------------------------------------------------
     * If passengers are updated, make sure to update the passengerRefs
     * (add or remove passengers) for all products, where this is desired
     *
     * - Each product stores independently, which passengers are part of it.
     * - Currently, all products always have the same passengers.
     * - Do not trigger sync if passengers are not used in the product
     *   (eg hotel doesnt have passengers, but rooms)
     */
    function updatePassengers(passengers: Passenger[]) {
        Object.values(productsMain.value)
            .filter(p => !p.config.disablePassengers)
            .forEach(product => product.passengerRefs = passengers.map(p => p.ref));
    }
    useWatchOnReady(() => passengerStore.passengers, updatePassengers, { deep: true });

    return {
        productsMain,
        productsUpsell,
        productsCurrent,
        productMain,
        productUpsell,

        productCurrent,
        productCurrentHotel,
        productCurrentActivity,
        productCurrentP2P,

        hashedProducts,

        saveUpsell,
        editUpsell,
        addProduct,
        removeProduct,
        removeProductUpsell,
    };
}
