import { isEqual } from 'ohash';

/**
 * Quotes
 * ----------------------------
 *
 * Manages a quote for all offers (holds final pricing)
 *
 */
export default function useQuote(offers: ComputedRef<Offers>) {
    /**
     * Quote
     * ---------------------------------------------------
     * The quote for all offers, showing final pricing
     */
    const quote = ref<Quote>();

    /**
     * Load quote for all offers
     * ---------------------------------------------------
     */
    async function loadQuote() {
        ({ data: quote.value } = await apiLoadQuote(Object.values(offers.value), usePassengerStore().passengersCurrent));
        return true;
    }

    /**
     * Watch offer changes
     * ---------------------------------------------------
     * If any of the offers change, reload the quote, so we always have up-to-date prices
     */
    useWatchOnReady(offers, (newVal, oldVal) => {
        if (isEqual(newVal, oldVal))
            return;

        loadQuote();
    }, { deep: true });

    /**
     * Reset Quote
     * ---------------------------------------------------
     * Usually if products are changed without new offers loaded,
     * we want to reset the quote to avoid showing old prices
     */
    function resetQuote() {
        quote.value = undefined;
    }

    return {
        quote,
        loadQuote,
        resetQuote,
    };
}
