export const configPassengerAges = {
    AGE_TODDLER: 0,
    AGE_CHILD: 6,
    AGE_HOTEL_ADULT: 12,
    AGE_TEEN: 16,
    AGE_ADULT: 20,
    AGE_SENIOR: 62,
};

export const configPassengerAgeDescriptions = {
    AGE_TODDLER: 'core.age.toddler',
    AGE_CHILD: 'core.age.child',
    AGE_TEEN: 'core.age.teen',
    AGE_ADULT: 'core.age.adult',
    AGE_SENIOR: 'core.age.senior',
};
