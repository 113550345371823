import { differenceInDays } from 'date-fns';
import { min } from 'radash';

export function transformHotels(response: ApiSchema['OfferCollectionResponse']): Hotel[] {
    return response.accommodations?.map((accommodation) => {
        const hotelOffers = response.offers
            ?.filter(offer => offer.accommodationOfferParts
                ?.some(aop => aop.accommodationRef === accommodation.id)) ?? [];
        const cheapestOffer = min(hotelOffers, o => o.accommodationOfferParts?.[0]?.price?.amount ?? 0);
        const cheapestOfferPart = cheapestOffer?.accommodationOfferParts?.[0];
        const numberOfNights = cheapestOfferPart?.validUntil ? Math.abs(differenceInDays(cheapestOfferPart?.validFrom, cheapestOfferPart?.validUntil)) : 1;
        const pricePerNightFrom = cheapestOffer?.offerSummary?.minimalPrice ?? { amount: 0, currency: 'CHF', vats: [{ amount: 0, countryCode: '' }] };
        pricePerNightFrom.amount = pricePerNightFrom.amount / numberOfNights;
        if (pricePerNightFrom?.vats?.[0]?.amount) {
            pricePerNightFrom.vats[0].amount = pricePerNightFrom.vats[0].amount / numberOfNights;
        }

        return {
            id: accommodation.id,
            name: accommodation.name ?? '',
            description: accommodation.description ?? '',
            infoText: accommodation.infoText ?? '',
            categories: accommodation.categories ?? [],
            rating: accommodation.rating,
            location: accommodation.location,
            address: accommodation.address,
            amenities: accommodation.amenities?.map(a => a.name) ?? [],
            images: accommodation.images?.map(a => a.url) ?? [],
            pricePerNightFrom,
            rooms: accommodation.rooms?.map(room => ({
                id: room.id,
                name: room.name ?? '',
                description: room.description ?? '',
                amenities: room.amenities?.map(a => a.name) ?? [],
                images: room.images?.map(a => a.url) ?? [],
                numberOfUnits: room.numberOfUnits ?? 1,
            } satisfies Room)) ?? [],
        } satisfies Hotel;
    }) ?? [];
}
