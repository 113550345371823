export function transformAvailableFilterOptions(response: ApiSchema['OfferCollectionResponse']): FilterGroup[] {
    const locations = Array.from(new Set(
        response.accommodations
            ?.map(accommodation => accommodation.address?.postCode)
            .map(pc => configHotelLocations.find(l => pc && l.postCodes.includes(pc))?.key),
    )) as string[];

    const ratings = Array.from(new Set(
        response.accommodations?.map(accommodation => accommodation.rating?.starRating?.rating ?? 0).filter(r => !!r) ?? [],
    ));

    const categories = Array.from(new Set(
        response.accommodations?.flatMap(accommodation => accommodation.categories ?? []) ?? [],
    ));

    return [
        { key: 'location', options: locations },
        { key: 'rating', options: ratings },
        { key: 'category', options: categories },
    ];
}
