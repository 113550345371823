/**
 * Trip Store
 * ----------------------------
 *
 * The trip store contains all data related to P2P trips. It calculates what trips to show for the user to select
 */
export const useTripStore = defineStore('trip', () => {
    // Popular places (default list of places when no search is done)
    const placesPopular = ref<Place[]>();

    // Results of places for a search (autocomplete)
    const places = ref<Place[]>();

    // List of loaded trips up
    const tripsUp = ref<Trip[]>([]);

    // List of loaded trips down (if return-trip)
    const tripsDown = ref<Trip[]>([]);

    // Show Earlier Connections in the  morning (before default start (e.g. 06:30)
    const {
        isEarlierVisible,
        indexEarliest,
        isEarlierButtonVisible,
    } = useEarlier(tripsUp);

    // Trips Up filtered (trips up to finally display)
    const {
        tripsUpFiltered,
    } = useTripsUpFiltered(tripsUp, tripsDown, isEarlierVisible, indexEarliest);

    // Show possible, selectable departure times (both departure/arrival times) for trip up (eg 06:30, 07:00, 07:30, ...)
    const {
        departureTimeMode,
        departureTime,
        departureTimes,
        updateDepartureTime,
    } = useDepartureTime(tripsUpFiltered);

    // Show possible, selectable stay durations between trip up and trip down (e.g. 1h, 1.5h, 2h, ...)
    const {
        stayDuration,
        stayDurations,
    } = useStayDuration(tripsDown);

    // Route variants are different ways to get up/down to Joch
    const {
        routeVariantUp,
        routeVariantDown,
    } = useJochVariants();

    // Load trips
    const {
        loadTrips,
        loadTripsUp,
        loadTripsDown,
    } = useLoadTrips(tripsUp, tripsDown, isEarlierVisible, departureTime, stayDuration, routeVariantUp, routeVariantDown);

    /**
     * Reset store
     */
    function reset() {
        placesPopular.value = undefined;
        places.value = undefined;
        tripsUp.value = [];
        tripsDown.value = [];
        isEarlierVisible.value = false;
        departureTimeMode.value = 'departureTime';
        departureTime.value = undefined;
        stayDuration.value = undefined;
        routeVariantUp.value = undefined;
        routeVariantDown.value = undefined;
    }

    return {
        placesPopular,
        places,

        tripsUp,
        tripsDown,
        tripsUpFiltered,
        isEarlierVisible,
        isEarlierButtonVisible,

        departureTimeMode,

        departureTime,
        departureTimes,
        updateDepartureTime,

        stayDuration,
        stayDurations,

        routeVariantUp,
        routeVariantDown,

        loadTrips,
        loadTripsUp,
        loadTripsDown,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
