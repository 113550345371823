import { addMinutes } from 'date-fns';

/**
 * Trips Up filtered
 * ------------------------------------------------------------------------------------------
 *
 *   "Final" list of trips UP to show
 *  - Strips out trips before the default start time if not showing earlier
 *  - Mark trips that are not possible due to stay duration
 *    (e.g. arriving at destination at 15:35, but last trip down is at 16:00 and minimum stay duration is 30 minutes)
 */
export default function useTripsUpFiltered(
    tripsUp: Ref<Trip[]>,
    tripsDown: Ref<Trip[]>,
    isEarlierVisible: Ref<boolean>,
    indexEarliest: ComputedRef<number>,

) {
    const shopStore = useShopStore();
    const product = computed(() => shopStore.productCurrentP2P);

    const tripsUpFiltered = computed(() => {
        let trips: Trip[] = [];

        // Either show all trips or only the ones after the default start time
        // By default, we only show connections AFTER default time (e.g. 07:00)
        // If earlier is visible, we show all trips
        trips = isEarlierVisible.value
            ? tripsUp.value
            : tripsUp.value.slice(indexEarliest.value);

        // Mark trips as hidden, if they are not possible due to stay duration
        // Hidden means: We hide them completely
        // Disabled means: We gray them out
        trips = trips.map((trip) => {
            const minStay = addMinutes(trip.arrivalTime, product.value?.config.stayDurationMin ?? 0);

            const isHidden = tripsDown.value
                .filter(tripDown => !tripDown.isDisabled)
                .every(tripDown => isAfterOrEqual(minStay, tripDown.departureTime));

            return {
                ...trip,
                isHidden,
            };
        });

        // For now, dont show hidden trips at all (filter our)
        // If we might show them in the feature (grayed out)
        // we can easily adjust this here
        trips = trips.filter(trip => !trip.isHidden);

        return trips;
    });

    return {
        tripsUpFiltered,
    };
}
