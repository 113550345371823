/**
 * Extracts an activity offer from a given offer (from an /offers request)
 *
 * @param offer The offer containing the activity
 * @returns The activity offer
 */
export function transformOfferActivity(
    offer: ApiSchema['Offer'],
    passengers: Passenger[],
    apiWarnings?: ApiSchema['WarningCollection'],
): OfferActivity | undefined {
    const passengerRefs = offer.passengerRefs;

    const offerParts = offer.activityOfferParts?.map((aop) => {
        return {
            type: 'activity',
            passengerRefs: aop.passengerRefs,
            name: aop.summary ?? '',
            price: aop.price,
        } satisfies OfferPartActivity;
    }) ?? [];

    const warnings = passengers.reduce((acc: Record<string, Warning[]>, passenger: Passenger) => {
        const warnings = apiWarnings?.warnings?.filter(w => w.instance?.includes(offer.offerId) && w.instance?.includes(passenger.ref));
        if (warnings) {
            acc[passenger.ref] = warnings;
        }
        return acc;
    }, {});

    return {
        offerId: offer.offerId,
        type: 'activity',
        passengerRefs,
        offerParts,
        warnings,
    } satisfies OfferActivity;
}
